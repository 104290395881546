.btn-primary {
  border-radius: 10px;
  background-color: var(--color-primary-blue);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #2e44a8;
  }
  &:active {
    background-color: #131d49;
  }
  &:disabled {
    background-color: var(--color-primary-grey);
  }
}

.btn-secondary {
  border-radius: 10px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--color-primary-blue);
  border: 1px solid var(--color-primary-blue);
  cursor: pointer;

  &:hover {
    color: #2e44a8;
    border: 1px solid #2e44a8;
  }
  &:active {
    color: #131d49;
    border: 1px solid #131d49;
  }
  &:disabled {
    background-color: var(--color-primary-grey);
    color: var(--color-primary-grey);
    border: 1px solid var(--color-primary-grey);
  }
}

.icon-primary {
  width: 50px;
  height: 50px;
  flex-grow: 0;
  border-radius: 15px;
  background-color: var(--color-primary-blue);
  svg {
    margin: auto;
  }
  &:hover {
    background-color: #2e44a8;
  }
  &:active {
    background-color: #131d49;
  }
  &:disabled {
    background-color: #f5f5f9;
    svg {
      filter: invert(84%) sepia(4%) saturate(1784%) hue-rotate(198deg)
        brightness(82%) contrast(89%);
    }
  }
}

.icon-secondary {
  width: 50px;
  height: 50px;
  flex-grow: 0;
  border-radius: 15px;
  background-color: rgba(235, 236, 243, 0.5);
  svg {
    margin: auto;
  }

  &:hover {
    background-color: rgba(166, 169, 200, 0.5);
  }

  &:active {
    background-color: rgba(130, 127, 155, 0.5);
  }
  &:disabled {
    background-color: rgba(235, 236, 243, 0.5);
    svg {
      filter: invert(84%) sepia(4%) saturate(1784%) hue-rotate(198deg)
        brightness(82%) contrast(89%);
    }
  }
}
