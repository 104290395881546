$base-color: #F4F4F4;
$shine-color: rgba(209, 213, 219, 1);
$animation-duration: 2.0s;

// this unfortunately uses set px widths for the background-gradient, I never got around to changing it to work with all widths :(
@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 600px;
}

.skeleton-line{
    float: left;
    height: 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    width: 100%;
    border-radius: 7px;
    
    @include background-gradient;
    animation: shine-lines $animation-duration infinite ease-out;
}

 
@keyframes shine-lines{
    0% { background-position: -100px;}
    40%, 100% {background-position: 500px;}
}

.card-specialist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 15px 16px 22px;
    width: 500px;
    border-radius: 20px;
    background-color: #fff;
    min-height: 90px;
    @media screen and (max-width: 768px) {
      padding: 5px;
    }
    @media screen and (min-width: 768px) {
      .buttons {
        margin-left: 33px;
        button:nth-child(2) {
          margin-right: 11px;
          margin-left: 11px;
        }
      }
    }
   span {
    color: black;
    font-size: 10;
    font-weight: bold;
   }
}

.menu{
  display: flex;
  .unselected{
    color: #666;
    padding: 12px 24px;
    // border-top-color: #666;
    // border-bottom-color: #666;
    // border-top-width: 1px;
    // border-bottom-width: 1px;
  }
  .selected{
    color: black;
    font-weight: bold;
    padding: 11px 24px;
    border-bottom-color: #6666c4;
    //border-top-width: 1px;
    border-bottom-width: 3px;
  }
}
.boxName{

  background-color: #fdf1dd;
  border-radius: 12px;
}
.line{
  border-color: #dbdbdb;
  border-width: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.custom-file-input {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.custom-file-input input {
  position: absolute;
  font-size: 999px;
  opacity: 0;
  right: 0;
  top: 0;
}

.custom-file-label {
  display: inline-block;

  color: rgb(1, 0, 0);
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Styling when file is selected */
.custom-file-input input:focus + .custom-file-label,
.custom-file-input input.has-files + .custom-file-label {
  background-color: #4565f6;
}
