
  
  .title {
    text-align: center;
  }
  
  .form-container {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 50%;
  }
  .auth-code-title {
    text-align: center;
    margin-bottom: 10px;
    
  }
  
  .auth-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .auth-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
.instruction-container {
    display: flex;
    // align-items: center;
    margin-top: 15px;
  }
  
  .icon-container {
    width: 30px;
    margin-right: 10px;
  }
  
  .phone-icon {
    width: 100%;
    height: auto;
  }
  
  .instruction-text {
    font-size: 14px;
    color: #555;
  }