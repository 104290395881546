.form-field {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .label-cell {
    padding: 8px; 
    
  }
  table {
    border-collapse: collapse;
  }
  
  // td:nth-child(2) {
  //   border-right: 2px solid #ddd; 
  // }