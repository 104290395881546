@keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
  }
  
  .animate-pulse {
    animation: pulse 1.5s ease-in-out infinite;
  }