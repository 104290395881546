

.containerMenuStudy{
    background-color: #9bc0f5;
    border: 1px solid #2564bf;
    cursor: pointer;
    .statusCard{
        background-color: #4565f6;
        //border-radius: 3px;
        font-size: 10px;
        //margin: 3px 3px 0;
    }
    .section{
        .sub-section{
            width:100%;
        }
    }
}
.titleMenuForm{
    color:#4565f6;
}