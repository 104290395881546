@keyframes stampwithOpacity {
  0% {
      transform: scale(1);
      opacity: 0;
  }
  50% {
      transform: translateY(-10px) scale(1.1);
      opacity: 0;
  }
  100% {
      transform: translateY(0) scale(1);
      opacity: 1;
  }
}
@keyframes stamp {
  0% {
      transform: scale(1);
      opacity: 0;
  }
  50% {
      transform: translateY(-10px) scale(1.1);
      opacity: 0;
  }
  100% {
      transform: translateY(0) scale(1);
      opacity: 1;
  }
}

/* Using Tailwind CSS's @apply directive */
.animate-stamp {
  animation: stampwithOpacity 0.5s ease-in-out;
}
.stamp-animation {
  animation: stamp 0.9s ease-in-out;
}


