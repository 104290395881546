.configMenu{
  min-width: 100%;
  .menuContainer {
    .selected-menu {
      background-color: #fff;
     min-width: 240px;
      border-radius: 20px;
      svg {
        filter: invert(36%) sepia(95%) saturate(3055%) hue-rotate(220deg)
          brightness(98%) contrast(97%) !important;
      }
      * {
        color: var(--color-primary-blue) !important;
      }
  }
  .text-menu{
    margin: 12px 0px 12px 12px;
  }
  }

}

.mobile-sidebar {
  background-color: var(--color-primary-grey-background);
  z-index: 10;
  height: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  padding: 20px 28px;
  width: 100%;
  box-shadow: 4px 0 10px 0 #0004;
}

.swipe-events-container {
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1vw;
}

.swipe-events-container.swipeable-fullscreen {
  width: 90vw;
}

.mobile-menu-burger {
  top: 0px;
  right: 0px;
  z-index: 1500;
}
@media screen and (max-width: 420px) {
  .configMenu1 {
    padding: 30px 10px !important;
    margin: auto 20px;

    input[type="text"] {
      width: 320px !important;
    }
    input[type="password"] {
      width: 320px !important;
    }
  }
}



