.card-specialist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 15px 16px 22px;
    width: 500px;
    border-radius: 20px;
    background-color: #fff;
    min-height: 90px;
    @media screen and (max-width: 768px) {
      padding: 5px;
    }
    @media screen and (min-width: 768px) {
      .buttons {
        margin-left: 33px;
        button:nth-child(2) {
          margin-right: 11px;
          margin-left: 11px;
        }
      }
    }
   span {
    color: black;
    font-size: 10;
    font-weight: bold;
   }
}

.menu{
  display: flex;
  .unselected{
    color: #666;
    padding: 12px 24px;
    // border-top-color: #666;
    // border-bottom-color: #666;
    // border-top-width: 1px;
    // border-bottom-width: 1px;
  }
  .selected{
    color: black;
    font-weight: bold;
    padding: 11px 24px;
    border-bottom-color: #6666c4;
    //border-top-width: 1px;
    border-bottom-width: 3px;
  }
}
.boxName{
  
  background-color: #fdf1dd;
  border-radius: 12px;

}
.line{
  border-color: #dbdbdb;
  border-width: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
}