.my-patients {
  .cros {
    padding-left: 9px;
    font-size: 9px;
  }
  .btnAddPatient {
    width: 182px;
    height: 50px;
  }
  .btnAddFiltre {
    width: 146px;
    height: 35px;
  }
  .filtres {
    height: 35px;
  }
  .patient-list {
    margin-top: 29px;
  }
}



.form-add-patient {
  .title {
    margin-top: 60px;
    margin-bottom: 34px;
  }
  .form-container {
    input:not([type="checkbox"]) {
      // width: 417px;
      height: 45px;
      padding: 10px 10px 11.5px 37px;
      border-radius: 9px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(166, 169, 200, 0.28);
      &::placeholder {
        font-size: 14px;
        line-height: 18px;
        color: #a6a9c8;
        height: 18px;
      }
    }
    .sexe {
      margin-right: 60px;
      .check {
        font-size: 14px;
        line-height: 16px;
        color: #a6a9c8;
        &:first-child {
          margin-left: 6px;
        }
      }
    }
    .particularite-container {
      .particularite {
        .check {
          font-size: 14px;
          line-height: 16px;
          color: #a6a9c8;
          &:first-child {
            margin-left: 6px;
          }
        }
        &:nth-child(1) {
          margin-right: 14px;
        }
      }
    }

    .inputTest {
      margin-bottom: 14px;
    }
    .btn {
      width: 170px;
      height: 50px;
    }
  }
}
.firstModal {
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  padding: 20px 40px 60px 40px;
  background: #ffffff;
  width: 700px;

  .buttons {
    margin-bottom: 17px;
    button {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  span {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #827f9b;
  }
}

.confirmAddPatient {
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  padding: 60px 40px 60px 40px;
  background: #ffffff;
  max-width: 521.79px;
  width: 80vw;
  .buttons {
    margin-bottom: 17px;
    button {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  span {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #827f9b;
  }
}

.fixingColor {
  svg {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(130deg)
      brightness(104%) contrast(106%);
  }
}

.chat-box {
  .input-chat-box {
    border-radius: 10px;
    border: solid 1px rgba(166, 169, 200, 0.28);
    background-color: rgba(255, 255, 255, 0.5);
  }

  .avatar-chat {
    border-radius: 50%;
    background-color: grey;
    width: 35px;
    height: 35px;
    flex-grow: 0;
  }
  .message-chat {
    flex-grow: 0;
    font-size: 16px;
    line-height: 1.25;
  }

  .me {
  }
  .you {
    color: white;
    background-color: var(--color-primary-blue);
    border-radius: 20px;
  }

  .hr-center-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: 0.5;
    &:before {
      content: "";
      // use the linear-gradient for the fading effect
      // use a solid background color for a solid bar
      background: linear-gradient(
        to right,
        transparent,
        var(--color-primary-grey),
        transparent
      );
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: black;

      padding: 0 0.5em;
      line-height: 1.5em;
      // this is really the only tricky part, you need to specify the background color of the container element...
      color: var(--color-primary-grey);
      background-color: #fcfcfa;
    }
  }
}

.header-chat-patient {
  .infos {
    background: #f5f5f9;
    border-radius: 20px;
    .sub-infos {
      .details {
        font-size: 13px;
        margin-top: 20px;
        .imcBox {
          width: 94.22px;
          height: 31.02px;
          background: rgba(166, 169, 200, 0.22);
          border-radius: 9px;
        }
        .name {
          height: 23px;
          font-weight: bold;
          font-size: 20px;
          color: #000000;
        }
        .phonemail {
          &.div:first-child {
            margin-right: 30px;
          }
        }
        .mail {
          margin-left: 31px;
        }
        .birth {
          margin-bottom: 10px;
          .sub-birth {
            img {
              width: 17px;
              height: 17px;
            }
          }
          .dateBirth {
            p {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
      .photo {
        width: 95px;
        height: 95px;
        margin: 20px 10px 20px 20px;
        border-radius: 20px;
        svg {
          width: 100px;
          height: 100px;
        }
      }
    }
    .patient-icons {
      margin: 10px 12.5px 10px 12.5px;
    }
  }
}
.card-patient {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  //max-width: 85vw;
  min-height: 123px;
  border-radius: 20px;
  .patient-info {
    margin-bottom: 13px;
    .details {
      padding-top: 23px;
      .card-subtitle {
        margin-bottom: 11px;
        span {
          font-weight: 700;
        }
      }
    }
  }
  .patient-icons {
    margin-bottom: 13px;
    .icon-secondary {
      margin: 10px;
    }
  }
}
@media screen and (max-width: 480px) {
  .card-patient {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    min-width: 100%;
    min-height: 123px;
    border-radius: 20px;
    .patient-info {
      margin-bottom: 13px;
      .details {
        padding-top: 23px;
        .card-subtitle {
          margin-bottom: 11px;
          span {
            font-weight: 700;
          }
        }
      }
    }
    .patient-icons {
      margin-bottom: 13px;
      .icon-secondary {
        margin: 10px;
      }
    }
  }
}
.selectedPatientDoctor {
  border: 3px solid #4565f6;
}
