input:focus,
textarea:focus,
select:focus {
  outline: none;
}

// .container-checkbox {
//   display: block;
//   position: relative;
//   padding-left: 35px;
//   margin-bottom: 18px;
//   cursor: pointer;
//   font-size: 22px;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;

//   /* Hide the browser's default checkbox */
//   input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
//   }

//   /* Create a custom checkbox */
//   .checkmark {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 18px;
//     height: 18px;
//     border-radius: 5px;
//     background-color: #eee;
//     border: solid 1px var(--color-primary-blue);
//   }

//   /* On mouse-over, add a grey background color */
//   &:hover input ~ .checkmark {
//     background-color: #ccc;
//   }

//   /* When the checkbox is checked, add a blue background */
//   input:checked ~ .checkmark {
//     background-color: #2196f3;
//   }
// }

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--color-primary-blue);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-primary-blue);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

// style radio button
// input[type="radio"] {
//   display: none;
// }
// input[type="radio"] + *::before {
//   content: "";
//   display: inline-block;
//   vertical-align: bottom;
//   width: 1rem;
//   height: 1rem;
//   margin-right: 0.3rem;
//   border-radius: 50%;
//   border-style: solid;
//   border-width: 0.1rem;
//   border-color: red;
// }
// input[type="radio"]:checked + * {
//   color: teal;
// }
// input[type="radio"]:checked + *::before {
//   background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
//   border-color: teal;
// }

.iconClass {
  div:first-child {
    position: absolute;
    padding: 7px 0px 15px 8px;
  }
}
.imgClass {
  div:first-child {
    width: 16px;
    height: 17px;
    margin: 15px 0px 0px 14px;
    position: absolute;
    img {
      width: 15px;
      height: 15px;
    }
  }
}
.imgClassRight {
  width: unset;
  position: absolute;
  right: 0;
  padding-right: 10px;
  img {
    width: 30px;
    // height: 28px;
    margin-top: 10px;
    margin-right: 10px;
  }
}
.leftImage {
  // width: unset;
  position: absolute;
  left: 0;
  top: 17px;
  padding-left: 15px;
  padding-right: 5px;
  img {
    width: 15px;
  }
}
