#stripe-payment-method {
  .card {
    position: relative;
    height: auto;
    border-radius: 15px;
    margin: 0 auto;
    padding: 40px 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    .title .fa {
      color: #fff;
      font-size: 60px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      text-align: center;
      line-height: 100px;
      -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    }

    .title h2 {
      position: relative;
      margin: 20px 0 0;
      padding: 0;

      font-size: 28px;
      z-index: 2;
    }
    .price,
    .option {
      position: relative;
      z-index: 2;
    }
    .option {
      margin-bottom: 20px;
    }
    .price h4 {
      margin: 0;
      padding: 20px 0;

      font-size: 60px;
    }
    .option ul {
      margin: 0;
      padding: 0;
    }
    .option ul li {
      margin: 0 0 10px;
      padding: 0;
      list-style: none;

      font-size: 16px;
    }
    button {
      position: relative;
      z-index: 2;
      background: rgb(239, 117, 52);
      color: #fff;
      width: 100px;
      height: 50px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      display: block;
      text-align: center;
      margin: 20px auto 0;
      cursor: pointer;
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
  }

  input,
  .StripeElement {
    display: block;
    margin: 20px auto 20px auto;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Ubuntu", sans-serif;
    box-shadow: rgba(202, 81, 21, 0.149) 5px 5px 5px,
      rgba(218, 164, 64, 0.02) 5px 5px 5px;
    border: 0;
    outline: 0;
    border-radius: 8px;
  }

  input::placeholder {
    color: #c10931;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(16, 218, 120, 0.11) 6px 4px 6px,
      rgba(127, 199, 78, 0.078) 6px 6px 6px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
}
.selectedPatientDoctor {
  border: 5px solid #4565f6;
}
.buttonPay {
  position: relative;
  z-index: 2;
  background: rgb(239, 117, 52);
  color: #fff;
  width: 100px;
  height: 50px;
  line-height: 40px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: block;
  text-align: center;
  margin: 20px auto 0;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
