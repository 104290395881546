.teleconsultation {
  .motif-border {
    background: rgba(166, 169, 200, 0.22);
    border-radius: 9px;
  }
}

.chat-teleconsultation {
  height: calc(100% - 60px);
  margin-bottom: -56px;
  // overflow-y: auto;
  overflow-x: hidden;
}
.chat-teleconsultation-doctor {
  height: calc(100% - 60px);
  // margin-bottom: -56px;
  // overflow-y: auto;
  overflow-x: hidden;
}

.tele-right-slide {
  height: 100%;
}

.modal-end-consultation {
  padding: 74.9px 61.9px 42.8px 62.9px;
  .menu {
    color: var(--color-primary-grey);
  }
}

.webrtc {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  // video {
  //   height: max-content;
  //   width: 300px;
  //   border-radius: 20px;
  //   overflow: hidden;
  //   margin: 1px;
  //   border: 4px solid gray;
  //   cursor: pointer;
  //   z-index: 100;
  // }

  .fullVideo {
    z-index: 1;
    width: 100vw;
    aspect-ratio: 16/9;
    object-fit: fill;
    max-height: 87vh;
    order: -1;
    flex: 1;
  }

  .-order-1 {
    order: -1;
  }
}
.control-call-container {
  position: absolute;
  bottom: 40px;

  height: 70px;
  z-index: 999;
  left: 0;
  right: 0;
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.shadow-black {
  box-shadow: 2px 2px 5px #bebebe;
}
.card-specialist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 15px 16px 22px;
  width: 500px;
  border-radius: 20px;
  background-color: #fff;
  min-height: 90px;
  @media screen and (max-width: 768px) {
    padding: 5px;
  }
  @media screen and (min-width: 768px) {
    .buttons {
      margin-left: 33px;
      button:nth-child(2) {
        margin-right: 11px;
        margin-left: 11px;
      }
    }
  }
  span {
    color: black;
    font-size: 10;
    font-weight: bold;
  }
}

.menu {
  display: flex;
  .unselected {
    color: #666;
    padding: 12px 24px;
    // border-top-color: #666;
    // border-bottom-color: #666;
    // border-top-width: 1px;
    // border-bottom-width: 1px;
  }
  .selected {
    color: black;
    font-weight: bold;
    padding: 11px 24px;
    border-bottom-color: #6666c4;
    //border-top-width: 1px;
    border-bottom-width: 3px;
  }
}
.boxName {
  background-color: #fdf1dd;
  border-radius: 12px;
}
.line {
  border-color: #dbdbdb;
  border-width: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.modalQuery {
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #a6a9c8;
  }
}

.myFullVideo {
  background-color: #333;
  border-radius: 10px;
  width: 100vw;
  z-index: 1;
  max-height: 87vh;
  order: -1;
}
.myvideo {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 100;
  width: 300px;
  height: max-content;
  border: 4px solid gray;
  z-index: 100;
}
.myvideoMobile {
  z-index: 100;
  width: 100vw;
  height: 40vh;
  background-color: #333;
  border: 4px solid gray;
  z-index: 100;
}

.local-video {
  z-index: 3;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  width: 15%;
  min-width: 240px;
  height: auto;
  margin: 5;
  background-color: black;
  border-radius: 15px;
  border: 4px solid #525f6d;
}

.local-video.alone {
  // width: 64%;
  // right: 50%;
  // bottom: 50%;
  // transform: translate(50%, 50%);
  left: 2rem;
  top: 2rem;
  width: 95%;
  height: 95%;
}

.remote-videos-grid {
  position: absolute;
  top: 4rem;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 2%;
}

.remote-video-container {
  z-index: 2;
  position: relative;
  display: inline-block;
  max-height: 97vh;
}

.remote-video {
  z-index: 1;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: black;
  border-radius: 15px;
  border: 4px solid #46a3e0;
  width: 100%;
  height: 100%;
  background-color: black;
}

.remote-video-label {
  font-family: Arial, Helvetica, sans-serif;
  z-index: 2;
  display: inline-block;
  position: absolute;
  top: 1em;
  left: 1em;
  text-align: start;
  margin: 0;
  color: white;
}
