.element {
   
    display: flex;
    justify-content: flex-start;
    position: relative;
    border: 1px dashed rgb(72, 144, 252);
    border-radius: 14px;
  
}

.element:hover {
    background-color: transparent;
    border: 1px dashed #efefef;
    background-color: rgba(72, 114, 252, 0.04);
    border-radius: 14px;
}

.sorter {
    margin-right:20px;
    flex: 0 0 40px;
    cursor: all-scroll ;
}

.action {
    right:0px;
    position:absolute;
    top:0;
    background-color: transparent;
    border-radius: 14px;
  
    float: left;
}

.formitem {
    flex: 1 1 auto;
}